import React, { forwardRef, useEffect, useState,useImperativeHandle,useRef } from 'react';
import { withRouter } from 'react-router-dom';
import './style.css';
import HairImage from '../../assets/images/hair_icon.png';
import ColorButton from '../../Components/Patient/ColorButton';
import IconColorButton from '../../Components/Patient/IconColorButton';
import Str from '../../constants/string';
import Color from '../../constants/usercolor';
import {HiddenMenu} from  '../../Components/Location/HiddenMenu'
import apiService from '../../services/api';
import { useSelector ,useDispatch} from 'react-redux';
import BackButton from '../../Components/Patient/BackButton';
import { toast } from 'react-toastify';
import storages from '../../constants/storages';
import { setScreens } from '../../redux/screen';
import Storages from '../../constants/storages';
import LockScreen from './LockScreen';
import QRCode from '../../Components/Location/QRCode';

const Screens = forwardRef((props,ref) => {
    const { isCalling, handleShowCallDialog,showKioskFrame } = props;
    const [logo, setLogo] = useState('');
    const [showLangPrompt, _setShowLangPrompt] = useState(window.i18n.multiLang)
    const showLangPromptRef = useRef(showLangPrompt)
    const setShowLangPrompt = (newVal) => {
      _setShowLangPrompt(newVal)
      showLangPromptRef.current = newVal;
    }
    const isCallingRef = useRef()
    isCallingRef.current = isCalling;
    // on this page we need a state for this to be able to change it
    const [callReceptionButtonText, setCallReceptionButtonText] = useState("") 
    const screens = useSelector((state) => state.screens)
    const [screen,setScreen]=useState({})
    const [title,setTitle]=useState('')
    const [subtitle,setSubtitle]=useState('')
    const [heading,setHeading]=useState('')
    const [stack,setStack]=useState([])
    const [kioskMode,setKioskMode]=useState(showKioskFrame)
    const dispatch = useDispatch()
    const [currentLangCode,setCurrentLangCode]=useState('en')
    const [showLockScreen, _setShowLockScreen] = useState(false)
    const showLockScreenRef = useRef(showLockScreen)
    const setShowLockScreen = (newVal) => {
      _setShowLockScreen(newVal)
      showLockScreenRef.current = newVal;
    }

    const company=localStorage.getItem(Storages.LOCAL_COMPANY_NAME)
    const locationName=localStorage.getItem(Storages.LOCAL_LOCATION_NAME)
    const idleTimeout = useRef(null);

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({

      BackToMain() {
        backToMain()
      }
    }));
    const backToMain=()=>{
      if(isCalling) return () => {}
      setKioskMode(false)
      window.i18n.currentLangCode = window.i18n.defaultLang; // when come back to this page, reset lang to default
      setShowLangPrompt(window.i18n.multiLang) // when come back to this page, go to lang prompt
      setCallReceptionButtonText(window.i18n.getString("callReceptionForHelp"))
      sendLangToReceptions();
      const shouldShowLockScreen = localStorage.getItem("lockScreenType") && localStorage.getItem("lockScreenType") != "0"
      if(!showLockScreenRef.current && shouldShowLockScreen) {
        setShowLockScreen(true)
      }
      // navigate to main screen
      let sc=screens.find((s)=>s.isMain== 1)
      if(!sc) return () => {toast.error("Page not found!")}

      setStack([sc.slug])
      setScreen(sc)
    }

    useEffect(() => {
      if (localStorage.getItem("lockScreenType") && localStorage.getItem("lockScreenType") != "0") {
        setShowLockScreen(true)
      } else {
        setShowLockScreen(false)
      }

      let logoName = localStorage.getItem("logo");
      if (!logoName || logoName === "null") {
        setLogo("")
      } else if (logoName.startsWith("http://") || logoName.startsWith("https://")) {
        setLogo(logoName)
      } else {
        setLogo(process.env.REACT_APP_UPLOAD_URL + logoName)
      }

      if(window.electron) {
        idleTimeout.current = setInterval(async () => {
          if(isCallingRef.current) return;

          const idleTime = await window.electron.getSystemIdleTime();
          if(idleTime > 30) {
            if(!showLangPromptRef.current && window.i18n.multiLang) {
              window.i18n.currentLangCode = window.i18n.defaultLang;
              setCallReceptionButtonText(window.i18n.getString("callReceptionForHelp"))
              setShowLangPrompt(true)
              sendLangToReceptions();
            }
          }
          if(idleTime > 60) {
            const shouldShowLockScreen = localStorage.getItem("lockScreenType") && localStorage.getItem("lockScreenType") != "0"
            if(!showLockScreenRef.current && shouldShowLockScreen) {
              setShowLockScreen(true)
              backToMain()
            }
          }
        }, 1000);
      }

      return () => {
        clearInterval(idleTimeout.current)
      }
    }, []);

    useEffect(()=>{
      var page={}
      if(stack.length>0){
        var slug=stack[stack.length-1]
        page=screens.find(sc=>sc.slug===slug)
        if(page){
          setScreen(page)
          if(page.isMain){
            setStack([page.slug])
          }
          return
        }
      }
      else {
        page=screens.find(sc=>sc.isMain===1)
        if(page && page.slug)  {
          let arr=[...stack]
          arr.push(page.slug)
          setStack(arr)
          setScreen(page)
        }
        else {
          // no found main screen
          dispatch(setScreens([]))
        }
      }
    },[screens])

    useEffect(()=>{
        setKioskMode(showKioskFrame)
        if(showKioskFrame) setScreen({})
        else if (!isCalling) backToMain()
    },[showKioskFrame])

    useEffect(() => {
      if(isCalling || kioskMode) {
          setShowLockScreen(false)
        return () => {}
      }

      backToMain()
    }, [isCalling])

    useEffect(()=>{
      let loc=localStorage.getItem(storages.LOCAL_LOCATION_NAME)
      let company=localStorage.getItem(storages.LOCAL_COMPANY_NAME)

      if(screen && screen.title){
        let titleTemp=screen.title[currentLangCode] || screen.title['en']
        titleTemp=titleTemp.replaceAll("[location]",loc)
        titleTemp = titleTemp.replaceAll("[company]",company)
        setTitle(titleTemp)
      }else setTitle('')
      if(screen && screen.subtitle){
        let subtitleTemp=screen.subtitle[currentLangCode]  || screen.subtitle['en']
        subtitleTemp=subtitleTemp.replaceAll("[location]",loc)
        subtitleTemp = subtitleTemp.replaceAll("[company]",company)
        setSubtitle(subtitleTemp)
      }else setSubtitle('')
      if(screen && screen.heading){
        let headingTemp=screen.heading[currentLangCode] || screen.heading['en']
        headingTemp=headingTemp.replaceAll("[location]",loc)
        headingTemp = headingTemp.replaceAll("[company]",company)
        setHeading(headingTemp)
      }else setHeading('')
    },[currentLangCode])

    useEffect(()=>{
      let loc=localStorage.getItem(storages.LOCAL_LOCATION_NAME)
      let company=localStorage.getItem(storages.LOCAL_COMPANY_NAME)
      if(screen && screen.title){
        let titleTemp=screen.title[currentLangCode] || screen.title['en']
        titleTemp=titleTemp.replaceAll("[location]",loc)
        titleTemp = titleTemp.replaceAll("[company]",company)
        setTitle(titleTemp)
      }else setTitle('')
      if(screen && screen.subtitle){
        let subtitleTemp=screen.subtitle[currentLangCode] || screen.subtitle['en']
        subtitleTemp=subtitleTemp.replaceAll("[location]",loc)
        subtitleTemp = subtitleTemp.replaceAll("[company]",company)
        setSubtitle(subtitleTemp)
      }else setSubtitle('')
      if(screen && screen.heading){
        let headingTemp=screen.heading[currentLangCode]  || screen.heading['en']
        headingTemp=headingTemp.replaceAll("[location]",loc)
        headingTemp = headingTemp.replaceAll("[company]",company)
        setHeading(headingTemp)
      }else setHeading('')
      // if(screen.isMain ==1 ){
      //   window.i18n.currentLangCode = window.i18n.defaultLang; // when come back to this page, reset lang to default
      //   setCurrentLangCode( window.i18n.defaultLang)
      //   setShowLangPrompt(window.i18n.multiLang) // when come back to this page, go to lang prompt
      //   setCallReceptionButtonText(window.i18n.getString("callReceptionForHelp"))
      //   sendLangToReceptions();
      // }
    },[screen])

    const changeLang = (lang) => {
      window.i18n.currentLangCode = lang.code
      setCallReceptionButtonText(window.i18n.getString("callReceptionForHelp"))
      setShowLangPrompt(false)
      sendLangToReceptions();
      setCurrentLangCode(lang.code)
    }

    const sendLangToReceptions = async () => {
      try{
        const sendLangResult = await apiService.changeLocationLang({
          newLang: window.i18n.currentLangCode
        })
        if(sendLangResult.data?.code !== 0) {
          console.error(8791, sendLangResult)
        }
      } catch (err) {
        console.error(9901, err)
      }
    }

    const handleCall = () => {
      if (isCalling) {
          return;
      }
      props.handleShowCallDialog(Str.STR_NORMAL, false);
    }

    const handleOnClickBtn=(btn)=>{
      if(btn.type==='redirect'){
        let slug=btn.value
        let sc=screens.find((s)=>s.slug===slug)
        if(!sc) return toast.error("Page not found!")
        if(sc.isMain){
          setStack([sc.slug])
        }
        else {
          let arr=[...stack]
          arr.push(sc.slug)
          setStack(arr)
        }
        setScreen(sc)
        return
      }
      if(btn.type==='call'){
        if(isCalling) return;
        let callTag=btn.value
        let callTagColor=btn.callColor
        handleShowCallDialog(callTag,false,callTagColor)
      }

      if(btn.type==='kiosk'){
        if (isCalling) return;
        // open kiosk
        if(localStorage.getItem(Storages.LOCAL_KIOSK_USER) == '1' && localStorage.getItem("integrated_kiosk") == "1") {
          handleShowCallDialog(Str.KIOSK, true);
          setScreen({})
          setKioskMode(true)
          return
        } else {
          let slug=btn.value
          let sc=screens.find((s)=>s.slug===slug)
          if(!sc) return  toast.error("Page not found!")
          if(sc.isMain){
            backToMain()
            return;
          } else {
            let arr=[...stack]
            arr.push(sc.slug)
            setStack(arr)
          }
          setScreen(sc)
          return
        }
      }
      if(btn.type==='webPage'){
        if (isCalling) return;
        // open web view
        let url=btn.value
        if(!url) return toast.error("Page not found!")
        handleShowCallDialog(Str.LAUNCH_WEB, false);
        setScreen({})
        setKioskMode(true)
        window.electron && window.electron.showWebView(url,localStorage.getItem("kiosk_timeout"))
      }
    }

    const handleBackButton=()=>{
      if(stack.length > 1){
        var arr=[...stack]
        arr.pop()
        let slug=stack[arr.length-1]
        if(slug){
          let sc=screens.find((s)=>s.slug===slug)
          if(!sc) {
            console.error(5691, "!sc in handleBackButton", sc, slug)
          }
          if(!sc || sc.isMain) {
            backToMain()
            return
          }

          setScreen(sc)
          setStack(arr)
        }
      }
    }

    return (
        <div className='root'>
            <QRCode />
            <HiddenMenu />
            <img className='top_tap' src={window.locationTopBanner} alt="top_tap" />
            <img className='bottom_tap' src={window.locationBottomBanner} alt="bottom_tap" />
            <div className='d-flex flex-column align-items-center justify-content-start w-100 h-100'> 
              <div className='d-flex align-items-end justify-content-center p-3' 
                style={{height: "25vh", width: "45vw"}}>
                { logo &&<img style={{maxHeight: "100%", maxWidth: "100%", zIndex: 1}} src={logo}/>}
              </div>
              { showLockScreen ?
                <>
                  <label className='welcom_lab'>
                    {window.i18n.getString("welcomeTo", { locationName: (!locationName || locationName === 'null' ? company : locationName) })}
                  </label>
                  <LockScreen logo={logo} handleClose={() => setShowLockScreen(false)} />
                </>
              : showLangPrompt ? 
                <>
                  <label className='welcom_lab mt-5'>
                    {window.i18n.getString("welcomeTo", { locationName: (!locationName || locationName === 'null' ? company : locationName) })}
                  </label>
                  <label className='main_lab mb-4'>
                    {window.i18n.getString("chooseLanguage")}
                  </label>
                  <div className='bottom_div mb-5'>
                    {window.i18n.langs.map(lang => (
                      <ColorButton onClicked={() => changeLang(lang)} Title={lang.title} Color={Color.PURPLE_BTN_COLOR}/>
                    ))}
                  </div>
                </> 
              : <>
                <label className='welcom_lab'>
                    {title}
                  </label>
                  <label className='sub_lab' id='welcom_sub_lab'>
                    {subtitle}
                  </label>
                  <label className='main_lab'>
                    {heading}
                  </label>
                  <div className='text-center row w-75 ' style={{zIndex:1,justifyContent:"center"}} >
                      {screen && screen.buttons && screen.buttons[0] && screen.buttons.map((btn)=>{
                          return(
                            <ColorButton onClicked={()=>handleOnClickBtn(btn)}   Color={isCalling ? Color.GRAY_BTN_COLOR :btn.color} shape={btn.shape}
                              Title={(currentLangCode && currentLangCode!='en')?btn[currentLangCode] || btn.text: btn.text} />
                          )
                      })}
                  </div>
                </>
                } 
            </div>
            {!showLockScreen ?
              <div id="call_div">
                {kioskMode ?
                  <ColorButton  
                  // Width='16.5vw'
                  onClicked={props.askForAssistance}
                  Color={isCalling ? Color.GRAY_BTN_COLOR : Color.BLUE_COLOR} 
                  Title={window.i18n.getString("askForAssistance")}/>
                :
                <IconColorButton onClicked={handleCall} Color={isCalling ? Color.GRAY_BTN_COLOR : Color.PURPLE_BTN_COLOR} 
                  Icon={HairImage} Title={callReceptionButtonText} />
                }
              </div>
            : ""}
            {
              stack.length > 1 &&
              <div id="back_div">
                  <BackButton onClicked={handleBackButton} />
              </div>
            }

        </div>
    )
})


export default Screens;