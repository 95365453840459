import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { toast } from 'react-toastify';
import apiService from '../../services/api';
import Modal from 'react-bootstrap/Modal';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { SpeedTest } from './SpeedTest';
import { TracerouteTest } from './TracerouteTest';
import LinearProgress from '@mui/material/LinearProgress';
import './modal-style.css'

const issueTypes = [{
  title: "Patient can not hear me",
  code: "location-dont-hear",
  isRelatedToLocation: true,
}, {
  title: "Patient can not see me",
  code: "location-dont-see",
  isRelatedToLocation: true,
}, {
  title: "I can not hear patient",
  code: "reception-dont-hear",
  isRelatedToLocation: true,
}, {
  title: "I can not see patient",
  code: "reception-dont-see",
  isRelatedToLocation: true,
}, {
  title: "General audio or video issue",
  code: "general-media-error",
  isRelatedToLocation: false,
}, {
  title: "Scanner issue",
  code: "scanner",
  isRelatedToLocation: false,
}, {
  title: "Printer issue",
  code: "printer",
  isRelatedToLocation: false,
}, {
  title: "Others",
  code: "others",
  isRelatedToLocation: false,
}]
const PREFIX = 'ReportIssue';

const classes = {
  modal: `${PREFIX}-modal`,
  modalBody: `${PREFIX}-modalBody`,
  modalFormControl: `${PREFIX}-modalFormControl`,
  modalFormSelect: `${PREFIX}-modalFormSelect`,
  modalFormButton: `${PREFIX}-modalFormButton`
};

const ModalStyled = styled(Modal)(({
  theme: {palette,mode}
}) => ({
  ['& .modal-header']:{
    backgroundColor:palette.background[mode] + " !important",
    color:palette.color[mode] + " !important"
  },
  ['& .modal-header .close'] : {
    color:palette.color[mode] + " !important"
  },
  ['& .modal-content']:{
    backgroundColor:palette.background[mode] + " !important",
    color:palette.color[mode] + " !important"
  },
  ['& .modal-footer'] : {
    backgroundColor:palette.background[mode] + " !important",
    color:palette.color[mode] + " !important"
  },
  [`& .${classes.modalBody}`]: {
    maxHeight: "80vh",
    overflow: "auto"
  },

  [`& .${classes.modalFormControl}`]: {
    '& label.Mui-focused': {
      color: palette.color[mode],
    },
    '& label': {
      color: palette.color[mode],
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: palette.color[mode],
    },
    '& .MuiOutlinedInput-root': {
      color: palette.color[mode],
      '& fieldset': {
        borderColor: palette.lightGray[mode],
      },
      '&:hover fieldset': {
        borderColor: palette.primary[mode],
      },
      '&.Mui-focused fieldset': {
        borderColor: palette.primary[mode],
      },
    },
  },

  [`& .${classes.modalFormSelect}`]: {
    backgroundColor:palette.background[mode] + " !important",
    color:palette.color[mode] + " !important",
  },

  [`& .${classes.modalFormButton}`]: {
    color: palette.primary[mode],
    borderColor: palette.primary[mode],
    '&:hover': {
      borderColor: palette.info[mode],
    },
    '&.Mui-focused': {
      borderColor: palette.info[mode],
    },
    '&:disabled': {
      color: palette.disabled[mode],
    }
  },
  "& .has-disable-color": {
    '&:disabled': {
      backgroundColor: palette.disabled[mode] + " !important",
    }
  },
  "& .hidden": {
    display: "none"
  },
  "& .override-mui-label": {
    "& .MuiFormControlLabel-label": {
      color: "unset",
      fontSize: "unset",
      letterSpacing: "unset",
      lineHeight: "unset",
      fontFamily: "unset",
      fontWeight: "unset",
    }
  }
}));

// const apiUrl = process.env.REACT_APP_API_URL;
// const jitsiUrl = process.env.REACT_APP_JITSI_DNS_NAME;

export const ReportIssue = ({ 
  open, 
  allLocations,
  engagedLocation,
  handleClose,
  handleOpen,
}) => {
  const [type, setType] = useState("others");
  const [isOpen, _setIsOpen] = useState(false);
  const [relatedLocation, setRelatedLocation] = useState(0); // for some reason empty string didn't work?!
  const [screenshotFile, setScreenshotFile] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [descTmp, setDescTmp] = useState("");
  const [monitor, setMonitor] = useState(0);
  const [availableMonitors, setAvailableMonitors] = useState({});
  const [hideInputs, setHideInputs] = useState(false);
  const [showWaitMsg, setShowWaitMsg] = useState(false);
  const [gatherInfoProgress, setGatherInfoProgress] = useState(0)
  const [performSpeedTest, setPerformSpeedTest] = useState(true);
  const mics = useSelector((state) => state.mics);
  const cameras = useSelector((state) => state.cameras);
  const speakers = useSelector((state)=>state.speakers)
  const descRef = useRef();
  const speedTestRef = useRef();
  const routeTestRef = useRef();
  const gatherInfoProgressInterval = useRef();
  const isOpenRef = useRef(isOpen)
  const setIsOpen = (newVal) => {
    isOpenRef.current = newVal
    _setIsOpen(newVal)
  }
  
  useEffect(() => {
    setIsOpen(open)
    if(open) {
      console.alert(133710, "Issue reporter opened.")
      if(window.electron) {
        (async () => {
          const availableMonitorsRes = await window.electron.availableMonitors();
          if (availableMonitorsRes.monitors.length < 2) {setMonitor(0)};
          if (availableMonitorsRes.code !== 0) {
            console.alert('could not get number of monitors!');
          } else {
            setAvailableMonitors(availableMonitorsRes.monitors);
          }
        })();
      }
    } else {
      setDescTmp(descRef.current?.value)
      console.alert(133711, "Issue reporter closed.")
    }
  }, [open])

  useEffect(() => {
    if(!relatedLocation) {
      setRelatedLocation(engagedLocation || 0);
    }
  }, [engagedLocation])

  async function togglePerformSpeedTest() {
    setPerformSpeedTest((prev) => !prev)
  }

  const reset = () => {
    setType("others")
    setRelatedLocation(0)
    setScreenshotFile(null)
    descRef.current.value = ""
    setDescTmp("");
    setDisableSubmit(false)
    setHideInputs(false)
    setShowWaitMsg(false)
    clearInterval(gatherInfoProgressInterval.current)
    setGatherInfoProgress(0)
  }

  const submit = async () => {
    try{
      setDisableSubmit(true)
      if(!type) {
        toast.error("Please choose an issue type", {autoClose: 5000})
        return; 
      }
      if(issueTypes.find(item => item.code == type)?.isRelatedToLocation && !relatedLocation){
        toast.error("Please choose a related location", {autoClose: 5000})
        return;
      }

      if(performSpeedTest) {
        setShowWaitMsg(true)
        setHideInputs(true)
        gatherInfoProgressInterval.current = setInterval(() => {
          setGatherInfoProgress((prevVal) => {
            return prevVal + 5 // we estimate that our tests would take about 30 seconds
          })
        }, 2 * 1000);
  
        if(!isOpenRef.current) {
          // we check isOpen multiple times to detect if user canceled the process
          return
        }
  
        try{
          await speedTestRef.current()
        } catch (err) {
          console.error(143720, err)
        }
      }

      if(!isOpenRef.current) {
        // we check isOpen multiple times to detect if user canceled the process
        return
      }

      console.alert(133701, "Issue reporter submitted.")
      console.alert(133702, "userAgent", window.navigator.userAgent)
      const locations = allLocations.map(item => item.username + "-" + item.status).join(",");
      console.alert(133703, "locations", locations)
      console.alert(133705, "cameras", cameras)
      console.alert(133707, "mics", mics)
      console.alert(133711, "speakers", speakers)
      console.alert(133714, "rttArr", localStorage.getItem("rttArr"))

      if(!isOpenRef.current) {
        // we check isOpen multiple times to detect if user canceled the process
        return
      }

      let logFile;
      if(window.electron) {
        const logRes = await window.electron.getLastLog();
        if(logRes.code !== 0) {
          console.error(133715, logRes)
          toast.error(`Error while saving logs. ${logRes.msg}. This issue will submit without logs.`, {autoClose: 10000})
        } else {
          logFile = logRes.data?.log;
        }
      } else {
        if(typeof window.dumpIndexDB === "function") {
          try{
            logFile = await window.dumpIndexDB()
          } catch (err) {
            console.error(133721, err)
            toast.error(`Error while saving logs. ${err.message}. This issue will submit without logs.`, {autoClose: 10000})
          }
        }
      }

      const result = await apiService.sendIssueReport({
        type, 
        desc: descRef.current.value, 
        relatedLocation: relatedLocation === 0 ? "" : relatedLocation, 
        recLog: logFile, 
        recSc: screenshotFile
      })
      if(!result.data || result.data.code !== 0) {
        toast.error(`Error while sending report. ${result.data.msg}`, {autoClose: 10000})
      } else {
        toast.success("Thank you for your report.")
        handleClose();
        if(window.electron && result.data.data?.reportID) {
          doTraceroute(result.data.data.reportID)
        }
        reset();
      }
    } catch (err) {
      console.error(133716, err)
      toast.error(`Error while sending report. ${err.message}`, {autoClose: 10000})
    } finally {
      setDisableSubmit(false)
      setShowWaitMsg(false)
      setHideInputs(false)
      clearInterval(gatherInfoProgressInterval.current)
    }
  }

  const doTraceroute = async (reportID) => {
    try{
      await routeTestRef.current()
      const logRes = await window.electron.getLastLog();
      if(logRes.code !== 0) {
        console.error(134715, logRes)
        return
      }
      const logFile = logRes.data?.log;
      await apiService.updateIssueReportLog({
        reportID,
        recLog: logFile, 
      })
    } catch (err) {
      console.error(144723, err)
    }
  }

  const confirmCancel = async () => {
    if(showWaitMsg) {
      window.confirmAsync.show(
        <h6 style={{fontSize: "1.35rem", marginBottom: "0px"}}>Confirm</h6>, 
        <h6 style={{fontSize: "1.15rem", marginBottom: "0px"}}>
          The report is not submitted yet. If you close it will discard. Are you sure?
        </h6>, 
        [
          { value: 0, color: "default", text: "No", close: 1 },
          { value: 1, color: "primary", text: "Yes", close: 1 } 
        ]
      ).then((value) => {
        if(value === 1) {
          handleClose();
          reset();
        }
      })
    } else {
      handleClose();
      reset();
    }
  }

  const onTypeChange = async (event) => {
    setType(event.target.value)
  }

  const onRelatedLocationChange = async (event) => {
    setRelatedLocation(event.target.value);
  }

  const browserTakeScreenShot = async (getDisplayMedia) => {
    let stream;
    try {
      // Capture screen
      stream = await getDisplayMedia({video: {displaySurface: ["browser", "monitor", "window"]}});
  
      // Create a video element and attach the captured stream
      const videoElement = document.createElement('video');
      videoElement.srcObject = stream;
      videoElement.muted = true; // Mute to avoid feedback
      videoElement.play()
      // Wait for the video to load metadata (important for canvas dimensions)
      await new Promise((resolve) => videoElement.onloadedmetadata = resolve);
  
      // Create a canvas element to draw the video frame
      const canvas = document.createElement('canvas');
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
  
      // Draw the current video frame onto the canvas
      const ctx = canvas.getContext('2d');
      ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
      const dataURL = canvas.toDataURL('image/png');
      // it seems stupid to convert base64 to uint8 and then again to base64. But I found no other way!
      stopStreamedVideo(videoElement)
      return Uint8Array.from(atob(dataURL.split(",").pop()), c => c.charCodeAt(0))
    } catch (error) {
      throw error;
    } finally {
      if(stream) {
        // Close the stream
        stream.getTracks().forEach(track => track.stop());
      }
    }
  }

  const takeScreenShot = async () => {
    try{
      handleClose();
      if(window.electron) {
        const screenshotRes = await window.electron.takeScreenShot(monitor);
        if(screenshotRes.code !== 0) {
          console.error(133703, screenshotRes)
          toast.error(`Error while taking screenshot. ${screenshotRes.msg}`, {autoClose: 10000})
        } else {
          setScreenshotFile(screenshotRes.data?.image);
        }
      } else {
        let getDisplayMedia;
        if (navigator.getDisplayMedia) {
            getDisplayMedia = navigator.getDisplayMedia.bind(navigator);
        } else {
            getDisplayMedia = navigator.mediaDevices.getDisplayMedia.bind(navigator.mediaDevices);
        }
        if(getDisplayMedia) {
          let screenShotImage = await browserTakeScreenShot(getDisplayMedia)
          setScreenshotFile(screenShotImage)
        } else {
          toast.error(`This browser does not support screenshot.`, {autoClose: 5000})
        }
      }
    } catch (err) {
      console.error(133717, err)
      toast.error(`Error while taking screenshot. ${err.message}`, {autoClose: 10000})
    } finally {
      handleOpen()
    }
  }

  function stopStreamedVideo(videoElem) {
    const stream = videoElem.srcObject;
    const tracks = stream.getTracks();
  
    tracks.forEach((track) => {
      track.stop();
    });
  
    videoElem.srcObject = null;
    videoElem.remove()
  }

  return (
    <>
      {window.electron && <TracerouteTest hideButton={true} routeTestRef={routeTestRef}/>}
      <ModalStyled show={isOpen} size={'lg'} onHide={confirmCancel} centered backdrop={false}>
        <Modal.Header closeButton>
          <h5 className='mb-0'>Report an Issue</h5>
        </Modal.Header>
        <Modal.Body className={classes.modalBody}>
          {showWaitMsg && <p className='mb-2'>
            We are gathering some data. 
            Please don't close VS or the issue report until the process is finished. 
            The test may temporarily impact your internet speed.
          </p>}
          <FormControl fullWidth variant="outlined" className={(hideInputs ? "hidden " : "") + classes.modalFormControl}>
            <InputLabel id="issue-type">Issue type</InputLabel>
            <Select
              label='Issue type'
              labelId="issue-type"
              onChange={onTypeChange}
              value={type}
              className={classes.modalFormSelect}
            >
              {issueTypes.map((item, index) => 
                <MenuItem key={index} value={item.code}>{item.title}</MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl fullWidth variant="outlined" className={(hideInputs ? "hidden " : "") + "mt-3 " + classes.modalFormControl}>
            <InputLabel id="related-location">Related location</InputLabel>
            <Select
              label="Related location"
              labelId="related-location"
              onChange={onRelatedLocationChange}
              value={relatedLocation}
              className={classes.modalFormSelect}
            >
              <MenuItem value={0}>Not related to a location</MenuItem>
              {allLocations.map((item, index) => 
                <MenuItem className={classes.modalMenuItem} key={index} value={item.username}>{item.locationname}</MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl fullWidth variant="outlined" className={(hideInputs ? "hidden " : "") + 'mt-3'}>
            <TextField
              id="others-desc"
              label="Please describe more"
              multiline
              rows={3}
              inputRef={descRef}
              variant="outlined"
              defaultValue={descTmp}
              className={classes.modalFormControl}
            />
          </FormControl>
          <div className={(!hideInputs ? "hidden" : "")}>
            <FormControl fullWidth variant="outlined">
              <SpeedTest type="reportIssue" speedTestRef={speedTestRef}/>
            </FormControl>
            <LinearProgress style={{height: "7px", marginTop: "10px"}} variant="determinate" value={Number(gatherInfoProgress) } />
          </div>
          <FormControl fullWidth variant="outlined" className={(hideInputs ? "hidden " : "") + 'mt-2'}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={performSpeedTest}
                  onChange={togglePerformSpeedTest}
                  color="primary"
                />
              }
              label={<p style={{marginBottom: 0}}>Perform a speed test</p>}
              className='mb-0 override-mui-label'
            />
            <label style={{fontSize: "14.5px", marginBottom: 0}}>
              If your issue is related to audio or video quality, 
              please include a network speed test. It will take less than thirty seconds
            </label>
          </FormControl>
          <FormControl fullWidth variant="outlined" className={(hideInputs ? "hidden " : "") + 'mt-3'}>
            {window.electron 
              ? <p className='mb-1'>
                Clicking the button bellow will minimize the Issue Reporter, 
                capture a screenshot from your screen 
                and then reopen the Issue Reporter. Please wait while this process finishes.
              </p>
              : <p className='mb-1'>
                By Clicking this button, your browser will ask your permission to capture your screen.<br/>
                In Mozilla Firefox: choose the item with Virtual Sally in it's name, usually ending with Firefox<br/>
                In Google Chrome and Edge: please go to window tab and choose your chrome's window
              </p>
            }
            {availableMonitors && availableMonitors.length > 1 ?
              <div className='py-2 d-flex'>
                {availableMonitors.map((mon, index)=> {
                  return (
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="screen" id={"screen_" + index} 
                      checked={monitor === index} onClick={() => {setMonitor(index)}}/>
                      <label class="form-check-label" for={"screen_" + index}>
                        {index === 0 ? 'Primary monitor' : mon.label ? mon.label : 'display-' + (index + 1)}
                      </label>
                    </div>
                  );
                })}
              </div>
              : ''
            }
            <Button className={classes.modalFormButton} variant='outlined' onClick={takeScreenShot}>
              Attach a screenshot
            </Button>
            {screenshotFile ?
              <>
                <img id='screenshot_preview' name='preview' className='w-100 my-3'
                  src={'data:image/png;base64,' + Buffer.from(screenshotFile).toString('base64')}
                />
                <div>Screenshot captured. <a href='javascript:void(0);' onClick={() => setScreenshotFile(null)}>Remove</a></div>
              </>
              : ''}
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="button-red dialog_btn"
            variant="contained"
            startIcon={<CloseIcon />}
            onClick={confirmCancel}
          >
            Cancel
          </Button>
          <Button
            className="button-green dialog_btn has-disable-color"
            variant="contained"
            disabled={disableSubmit}
            startIcon={<SaveIcon />}
            onClick={submit}
           >
            Submit
          </Button>
        </Modal.Footer>
      </ModalStyled> 
    </>
  );
}