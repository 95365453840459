import React ,{useState,useEffect, useContext} from 'react'
import { styled } from '@mui/material/styles';
import {Modal,Col,Row} from 'react-bootstrap';
import {ListItem,ListItemIcon,Switch,ListItemText, Slider} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import VideocamIcon from '@mui/icons-material/Videocam';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import {ThemeToggleContext} from '../../context/theme'
import Brightness4Icon from '@mui/icons-material/Brightness4';
import {setShowSecondaryLocations} from '../../redux/showSecondaryLocations';
import { useSelector } from 'react-redux';
import redux from '../../redux/store';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AudioDevices from '../Controls/AudioDevices';
import CameraDevices from '../Controls/CameraDevices';
import Account from './Account';
import AdminService from '../../services/api'
import VersionComponent from '../../Version'
import SpeedIcon from '@mui/icons-material/Speed';
import storages from '../../constants/storages';
const PREFIX = 'SettingsModal';

const classes = {
  root: `${PREFIX}-root`,
  item: `${PREFIX}-item`,
  verticalDivider: `${PREFIX}-verticalDivider`,
  modalBody: `${PREFIX}-modalBody`
};


const SecondarySwitch = styled(Switch)(({
  theme: {palette,mode}
}) => ({
  item:{
    color:palette.color[mode]
  },
}))

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const StyledModal = styled(Modal)(({
  theme: {palette,mode}
}) => ({
  '& .modal-content':{
    backgroundColor:palette.background[mode] + " !important",
    color:palette.color[mode] + " !important"
  },
  '& .modal-header .close' : {
    color:palette.color[mode] + " !important"
  },

  [`& .${classes.item}`]: {
    color:palette.color[mode]
  },

  [`& .${classes.verticalDivider}`]: {
    borderRight: "1px solid",
    borderColor: palette.lightGray[mode] + " !important",
  },

  [`& .${classes.modalBody}`]: {
    height: "50vh",
    '@media (max-height: 700px)': {
      height: "60vh"
    },
    overflow: "auto"
  },

  "& .slider-labels": {
    color:palette.color[mode],
  },

  "& .slider-labels-active": {
    color:palette.primary[mode],
  }
}));

export const SettingsModal=({
    showModal,
    handleClose,
    toggleBlur,
    callState,
    nonElectronUserLeave,
    updateReceiverConstraints
})=>{
  const [index,setIndex]=useState(0)
  const {toggleMode, mode} = useContext(ThemeToggleContext)
  const showSecondaryLocations = useSelector((state) => state.showSecondaryLocations)
  const [showToggleSecondary, setShowToggleSecondary] = useState(false);
  const [isBlurNow, setIsBlurNow] = useState(false);
  const [defaultPerformance,setDefaultPerformance]=useState(100)
  useEffect(()=>{
    setShowToggleSecondary(localStorage.getItem("showToggleSecondary"))
    setIsBlurNow(localStorage.getItem("blurMyBackground"))
    setIndex(0)
    let height=localStorage.getItem(storages.MAX_HEIGHT_VIDEO)
    if(height==1080) setDefaultPerformance(100)
    else if(height==720) setDefaultPerformance(80)
    else if(height==480) setDefaultPerformance(60)
    else if(height==320) setDefaultPerformance(40)
    else if(height==180) setDefaultPerformance(20)
    else if(height==0) setDefaultPerformance(0)
  },[showModal])

  const handleListItemClick=(ev,val)=>{
    setIndex(val)
  }


  const handleReload=()=>{
      if(callState){
        window.confirmAsync.show(
          <h6 style={{fontSize: "1.15rem", marginBottom: "0px"}}>Reload</h6>, 
          <span style={{fontSize: "1.05rem", marginBottom: "0px"}}>
            Call in progress. Are you sure?
          </span>, 
          [
            { value: 1, color: "primary", text: "Yes", close: 1 }, 
            { value: 0, color: "default", text: "No", close: 1 }
          ],'md'
        ).then((value) => {
          if(value === 1) {
            AdminService.createActivityLog({
              tags:['reload', 'reception'],
              action: "reload",
              subAction: "while-in-call",
              target: "__ME__",
              details: "Reception reloaded vs while call was in progress."
            })
            if(window.electron) {
              window.electron.reload();
            } else {
              window.location.replaceWithoutWarn("#/")
            }
          }
        })
      } else {
        AdminService.createActivityLog({
          tags:['reload', 'reception'],
          action: "reload",
          target: "__ME__",
          details: "Reception reloaded vs"
        })
        if(window.electron) {
          window.electron.reload();
        } else {
          window.location.replaceWithoutWarn("#/")
        }
      }
      handleClose()
  }

  const handleToggleDarkMode=()=>{
    toggleMode()
  }

  const handleExit=()=>{
    if(window.electron) {
      window.electron.closeApp();
    } else {
      nonElectronUserLeave();
    }
  }

  const toggleSecondary = () => {
    if(showSecondaryLocations) {
      localStorage.removeItem("showSecondaryLocations")
      redux.dispatch(setShowSecondaryLocations(false))
    } else {
      localStorage.setItem("showSecondaryLocations", "1")
      redux.dispatch(setShowSecondaryLocations(true))
    }
  }

  const toggleBlurLocal = () => {
    setIsBlurNow((prev) => !prev)
    toggleBlur();
  }
  const handleChangePerformance=(e,newValue)=>{
    console.log(newValue)
    setDefaultPerformance(newValue)
    if(newValue ==100) localStorage.setItem(storages.MAX_HEIGHT_VIDEO,1080)
    if(newValue ==80) localStorage.setItem(storages.MAX_HEIGHT_VIDEO,720)
    if(newValue ==60) localStorage.setItem(storages.MAX_HEIGHT_VIDEO,480)
    if(newValue ==40) localStorage.setItem(storages.MAX_HEIGHT_VIDEO,320)
    if(newValue ==20) localStorage.setItem(storages.MAX_HEIGHT_VIDEO,180)
    if(newValue ==0) localStorage.setItem(storages.MAX_HEIGHT_VIDEO,0)
    updateReceiverConstraints()
  }
    return (
      <>
        <StyledModal  show={showModal} size='lg' onHide={handleClose} keyboard={false} centered >
           <Modal.Header closeButton>
                <Modal.Title>Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body className={classes.modalBody + " show-grid"}>
              <Row>
                <Col className={classes.verticalDivider} xs={4} md={4}>
                  <ListItem button key={'General'} selected={index===0}  onClick={(event) => handleListItemClick(event, 0)}>
                      <ListItemIcon> <SettingsIcon className={classes.item} /> </ListItemIcon>
                      <ListItemText className={classes.item} primary={
                        <span className={'h6 ' + classes.item}>General</span>
                      }/>
                  </ListItem>
                  <ListItem button key={'Audio'} selected={index===1}  onClick={(event) => handleListItemClick(event, 1)}>
                      <ListItemIcon> <VolumeDownIcon className={classes.item} /> </ListItemIcon>
                      <ListItemText className={classes.item} primary={
                        <span className={'h6 ' + classes.item}>Audio</span>
                      }/>
                  </ListItem>
                  <ListItem button key={'Video'} selected={index===2}  onClick={(event) => handleListItemClick(event, 2)}>
                      <ListItemIcon> <VideocamIcon className={classes.item} /> </ListItemIcon>
                      <ListItemText className={classes.item} primary={
                        <span className={'h6 ' + classes.item}>Video</span>
                      }/>
                  </ListItem>
                  <ListItem button key={'Account'} selected={index===3}  onClick={(event) => handleListItemClick(event, 3)}>
                      <ListItemIcon> <AccountCircleIcon className={classes.item} /> </ListItemIcon>
                      <ListItemText className={classes.item} primary={
                        <span className={'h6 ' + classes.item}>Account</span>
                      }/>
                  </ListItem>
                </Col>
                <Col xs={8} md={8}>
                  {index===0?
                    <div className='d-flex flex-column'>
                      {showToggleSecondary && 
                        <ListItem button key={'Show-secondary'} onClick={toggleSecondary} className='pl-2'
                          title= {showSecondaryLocations ? "Hide secondary locations (alternates)" : "Show secondary locations (alternates)"}>
                          <ListItemIcon >
                            <SecondarySwitch
                              className={classes.item}
                              checked={showSecondaryLocations}
                              size='small'
                              />
                          </ListItemIcon>
                          <ListItemText primary={
                            <span className={"pl-2 " + classes.item}>
                              {showSecondaryLocations ? "Hide secondary" : "Show secondary"}
                            </span>
                          }/>
                        </ListItem>
                      }
                      <ListItem  button key={'Theme'} onClick={handleToggleDarkMode}>
                          <ListItemIcon > <Brightness4Icon className={classes.item} /> </ListItemIcon>
                          <ListItemText primary={
                            <span className={classes.item}>
                              {(mode==='dark')? "Light Mode" : "Dark Mode" }
                            </span>
                          }/>
                      </ListItem>
                      <ListItem button key={'Reload'} onClick={handleReload}>
                          <ListItemIcon > <ReplayOutlinedIcon className={classes.item} /> </ListItemIcon>
                          <ListItemText primary={
                            <span className={classes.item}>
                              Reload
                            </span>
                          }/>
                      </ListItem>
                      <ListItem  key={'performance'}>
                          <ListItemIcon > <SpeedIcon className={classes.item} /> </ListItemIcon>
                          <ListItemText primary={
                            <span className={classes.item}>
                              Performance 
                            </span>
                          }/>
                      </ListItem>
                      <ListItem className='container w-75'  >
                        <Slider
                          onChange={handleChangePerformance}
                          key={'slider '+defaultPerformance}
                          defaultValue={defaultPerformance}
                          step={20}
                          marks={[
                            {value:0,label:"Best performance"},
                            {value:20,label:"180"},
                            {value:40,label:"320"},
                            {value:60,label:"480"},
                            {value:80,label:"720"},
                            {value:100,label:"Higher Quality"}
                          ]}
                          valueLabelDisplay="off"
                          classes={{ markLabel: "slider-labels", markLabelActive: "slider-labels-active" }}
                        />
                      </ListItem>
                      <ListItem button key={'Exit-App'} onClick={handleExit}>
                          <ListItemIcon > <ExitToAppOutlinedIcon className={classes.item} /> </ListItemIcon>
                          <ListItemText primary={
                            <span className={classes.item}>
                              {window.electron ? "Exit" : "Logout"}
                            </span>
                          }/>
                      </ListItem>
                    </div>:""
                  }
                  {index===1 &&
                    <AudioDevices show={ index===1} audiosID={["locationAudio","mainRAudio","alarmAudio"]}/>
                  } 
                  { index===2 &&
                    <CameraDevices show={index===2}/>
                  }
                  { index === 3 &&
                    <Account handleClose={handleClose}/>
                  }
                </Col>
              </Row>
            </Modal.Body>
            <VersionComponent/>
        </StyledModal>
      </>

    )
}