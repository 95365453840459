import React from "react";
import { styled } from '@mui/material/styles';
import { useEffect } from "react";
import { useState } from "react";
import DraggableModal from "../Modal/DraggableModal";
import {
  Button,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const Thumbnail = styled('div')({
  position: "absolute", 
  right: "1.5rem", 
  top: "1.5rem", 
  zIndex: 2, 
  borderRadius: "1rem", 
  width: "6rem", 
  height: "7rem", 
  backgroundColor: "white",
  textAlign: "center",
  cursor: "pointer",
  fontSize: "13px",
  paddingTop: "7px",
  wordBreak: "break-word",
  wordWrap: "break-word",
  overflowWrap: "break-word",
});

const CloseButton = styled(Button)(({
  theme: {palette,mode}
}) => ({
  width: "100%",
  backgroundColor: palette.info[mode],
  color: palette.getContrastText(palette.info[mode]),
  '&:hover': {
    backgroundColor: '#0022ff',
  },
}))

export default function QRCode ({}){
    const [showModal, setShowModal] = useState(false)
    const qrCodeSrc = localStorage.getItem("qrCodeSrc")
    const qrCodeText = localStorage.getItem("qrCodeText")

    const handleCloseModal = () => {
      setShowModal(false)
    }

    return (
      <>
      {qrCodeSrc &&
        <>
          <Thumbnail onClick={() => setShowModal(true)}>
            <b>{qrCodeText}</b>
            <img src={qrCodeSrc} width="100%"/>
          </Thumbnail>
          <DraggableModal
            closeButton={false}
            onCloseButton={handleCloseModal}
            header={
              <h5 style={{textAlign: "center"}}>{qrCodeText}</h5>
            }
            body={<>
              <img src={qrCodeSrc} width="100%" style={{maxHeight: "70vh"}}/>
            </>}
            footer={<>
              <CloseButton
                className="dialog_btn"
                variant="contained"
                startIcon={<CloseIcon />}
                onClick={handleCloseModal}>
                Close
              </CloseButton>
            </>}
            show={showModal}
            bodyClassOverride="p-0"
            size="sm"
            onBackdropClick={handleCloseModal}
          />
        </>}
      </>
    );
}
 
