import { createSlice } from '@reduxjs/toolkit';

export const RemoteStats=createSlice({
    name:"remoteStats",
    initialState:{},
    reducers:{
      setAllRemoteStats:(state, action)=>{
        state = action.payload
        return state
      },
      setRemoteStat:(state, action)=>{
        let {id,remoteStat}=action.payload
        if(!id) return state
        state[id]=remoteStat
        return state
      },
      clearRemotesID:(state,action)=>{
        var remotesId=action.payload
        var newStates={}
        for(let id of remotesId) {
            newStates[id]=state[id]
        }
        state=newStates
        return state
      }
    }
})

export const { setRemoteStat , clearRemotesID, setAllRemoteStats} = RemoteStats.actions

export default RemoteStats.reducer