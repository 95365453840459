import Cookies from "js-cookie"

export default {
  get: () => {
    return Cookies.get('version')
  },
  set: (version) => {
    return Cookies.set('version', version, {expires: 1})
  },
  remove: () => {
    return Cookies.remove('version')
  },
}