import React, { useEffect, useRef, useState } from 'react';

const RttChart = (props) => {
  const {localConnectionQuality} = props
  const [rttArr, setRttArr] = useState([0])
  const [maxRtt, setMaxRtt] = useState(10)

  useEffect(() => {
    const oldLocalRttArr = JSON.parse(localStorage.getItem("rttArr"))
    if(Array.isArray(oldLocalRttArr) && oldLocalRttArr[0]) {
      setRttArr(oldLocalRttArr)
      setMaxRtt(Math.max(...oldLocalRttArr.map(item => item.rtt)))
    }
  }, [])

  useEffect(() => {
    let localRttArr = [...rttArr];
    let newNumber = localConnectionQuality?.jvbRTT
    if(newNumber && newNumber > 0) {      
      if(newNumber > 400) {
        newNumber = 400
      }
      localRttArr.push({date: Date.now(), rtt: newNumber})
      if (localRttArr.length > 40) {
        localRttArr.shift();
      }
  
      localStorage.setItem("rttArr", JSON.stringify(localRttArr))
      setRttArr(localRttArr)
    }

    setMaxRtt(Math.max(...localRttArr.map(item => item.rtt)))
  }, [localConnectionQuality])

  return (
    <div style={{float: "right", whiteSpace: "nowrap"}}>
      {rttArr.map((rtt, index) => {
        const rttDate = new Date(rtt.date);
        return (
          <div title={`${rtt.rtt} at ${rttDate.getHours() + ":" + rttDate.getMinutes() + ":" + rttDate.getSeconds()}`} key={index} style={{ 
            display: "inline-block",
            width: "4px", 
            backgroundColor: `${rtt.rtt > 200 ? "red" : "green"}`, 
            height: `${rtt.rtt*20/maxRtt}px`,
          }}>&nbsp;</div>
        )
      })}
    </div>
  )
};

export default RttChart;